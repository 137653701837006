<template>
    <card>
      <body-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.details') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12">
                <b-tabs content-class="mt-0" fill>
                  <b-tab :title="$t('license_management.application')" active>
                    <div v-if="loading">
                      <Loading />
                    </div>
                    <b-overlay v-else>
                      <div class="p-3">
                        <b-row>
                          <b-col sm="12">
                            <div class="text-right">
                              <!-- <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                              </b-button> -->
                            </div>
                            <div>
                              <div class="stepper-wrapper">
                                <div :class="item.value <= appDetail.status ? `stepper-item completed` : `stepper-item`"
                                  v-for="(item, index) in stepList" :key="index">
                                  <div class="step-counter">
                                    <i v-if="item.value <= appDetail.status" class="ri-check-fill"></i>
                                    <span v-else>{{ $n(index+1) }}</span>
                                  </div>
                                  <div class="step-name">{{ item.text }}</div>
                                </div>
                              </div>
                              <!-- Application view start -->
                              <div class="application-form-wrapper application-form-view-wrapper mt-5">
                                <div class="application-itmes">
                                  <div class="group-form-card">
                                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.general_information')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('national_award.circular')" label-for="circular">
                                            <p class="input-field">{{ getCircularName(appDetail.circular_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('national_award.category')" label-for="category">
                                            <p class="input-field">{{ getCategoryName(appDetail.category_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.applicant_name')" label-for="ApplicantNameEn">
                                            <p class="input-field"> {{ currentLocale === 'en' ? appDetail.applicant_name_en : appDetail.applicant_name_bn }} </p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.mobile')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.applicant_mobile | mobileNumber }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.email')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.applicant_email }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.designation')" label-for="DesignationEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.applicant_designation_en : appDetail.applicant_designation_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.applicant_company')" label-for="DesignationEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.company_name_en : appDetail.company_name_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('portal.org_type')" label-for="DesignationEn">
                                            <p class="input-field">{{ getOrgTypeName(appDetail.org_type) }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                  </div>
                                   <div class="group-form-card">
                                    <b-form-group label-cols-lg="3" :label="$t('national_award.application_details')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6" v-if="appDetail?.detail?.avg_prod_per_acor">
                                          <b-form-group :label="$t('portal.avg_prod_per_acor')" label-for="avg_prod_per_acor">
                                            <p class="input-field">{{ $n(appDetail.detail.avg_prod_per_acor) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.avg_price_of_tea_auction">
                                          <b-form-group :label="$t('portal.avg_price_of_tea_auction')" label-for="avg_price_of_tea_auction">
                                            <p class="input-field">{{ $n(appDetail.detail.avg_price_of_tea_auction) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.gross_annual_production">
                                          <b-form-group :label="$t('portal.gross_annual_production')" label-for="gross_annual_production">
                                            <p class="input-field">{{ $n(appDetail.detail.gross_annual_production) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.tea_cultivation_land_percentage">
                                          <b-form-group :label="$t('portal.tea_cultivation_land_percentage')" label-for="tea_cultivation_land_percentage">
                                            <p class="input-field">{{ $n(appDetail.detail.tea_cultivation_land_percentage) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.avg_price_of_tea_exported">
                                          <b-form-group :label="$t('portal.avg_price_of_tea_exported')" label-for="avg_price_of_tea_exported">
                                            <p class="input-field">{{ $n(appDetail.detail.avg_price_of_tea_exported) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.total_tea_export_volume">
                                          <b-form-group :label="$t('portal.total_tea_export_volume')" label-for="total_tea_export_volume">
                                            <p class="input-field">{{ $n(appDetail.detail.total_tea_export_volume) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.number_of_countries_exported">
                                          <b-form-group :label="$t('portal.number_of_countries_exported')" label-for="number_of_countries_exported">
                                            <p class="input-field">{{ $n(appDetail.detail.number_of_countries_exported) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.total_exported_qty_value_added_tea">
                                          <b-form-group :label="$t('portal.total_exported_qty_value_added_tea')" label-for="total_exported_qty_value_added_tea">
                                            <p class="input-field">{{ $n(appDetail.detail.total_exported_qty_value_added_tea) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.total_cultivation_area_under_25_acor">
                                          <b-form-group :label="$t('portal.total_cultivation_area_under_25_acor')" label-for="total_cultivation_area_under_25_acor">
                                            <p class="input-field">{{ $n(appDetail.detail.total_cultivation_area_under_25_acor) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.value_added_tea_sale_qty">
                                          <b-form-group :label="$t('portal.value_added_tea_sale_qty')" label-for="value_added_tea_sale_qty">
                                            <p class="input-field">{{ $n(appDetail.detail.value_added_tea_sale_qty) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.max_gross_annual_production_local">
                                          <b-form-group :label="$t('portal.max_gross_annual_production_local')" label-for="max_gross_annual_production_local">
                                            <p class="input-field">{{ $n(appDetail.detail.max_gross_annual_production_local) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.max_gross_annual_production_foreign">
                                          <b-form-group :label="$t('portal.max_gross_annual_production_foreign')" label-for="max_gross_annual_production_foreign">
                                            <p class="input-field">{{ $n(appDetail.detail.max_gross_annual_production_foreign) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.number_of_workers">
                                          <b-form-group :label="$t('portal.number_of_workers')" label-for="number_of_workers">
                                            <p class="input-field">{{ $n(appDetail.detail.number_of_workers) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.number_of_house">
                                          <b-form-group :label="$t('portal.number_of_house')" label-for="number_of_house">
                                            <p class="input-field">{{ $n(appDetail.detail.number_of_house) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.number_of_toilets">
                                          <b-form-group :label="$t('portal.number_of_toilets')" label-for="number_of_toilets">
                                            <p class="input-field">{{ $n(appDetail.detail.number_of_toilets) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.number_of_tubewell">
                                          <b-form-group :label="$t('portal.number_of_tubewell')" label-for="number_of_tubewell">
                                            <p class="input-field">{{ $n(appDetail.detail.number_of_tubewell) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_hospital">
                                          <b-form-group :label="$t('portal.have_hospital')" label-for="have_hospital">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_hospital) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_hospital_bed">
                                          <b-form-group :label="$t('portal.have_hospital_bed')" label-for="have_hospital_bed">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_hospital_bed) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_doctors">
                                          <b-form-group :label="$t('portal.have_doctors')" label-for="have_doctors">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_doctors) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_compounder">
                                          <b-form-group :label="$t('portal.have_compounder')" label-for="have_compounder">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_compounder) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_dispensary">
                                          <b-form-group :label="$t('portal.have_dispensary')" label-for="have_dispensary">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_dispensary) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.number_of_students">
                                          <b-form-group :label="$t('portal.number_of_students')" label-for="number_of_students">
                                            <p class="input-field">{{ $n(appDetail.detail.number_of_students) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_scholarship">
                                          <b-form-group :label="$t('portal.have_scholarship')" label-for="have_scholarship">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_scholarship) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_playground">
                                          <b-form-group :label="$t('portal.have_playground')" label-for="have_playground">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_playground) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_disable_people">
                                          <b-form-group :label="$t('portal.have_disable_people')" label-for="have_disable_people">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_disable_people) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_training_facility">
                                          <b-form-group :label="$t('portal.have_training_facility')" label-for="have_training_facility">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_training_facility) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_day_care">
                                          <b-form-group :label="$t('portal.have_day_care')" label-for="have_day_care">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_day_care) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_mothers_club">
                                          <b-form-group :label="$t('portal.have_mothers_club')" label-for="have_mothers_club">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_mothers_club) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.have_pregnant_women_health_service">
                                          <b-form-group :label="$t('portal.have_pregnant_women_health_service')" label-for="have_pregnant_women_health_service">
                                            <p class="input-field">{{ getYesNoName(appDetail.detail.have_pregnant_women_health_service) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.workers_type">
                                          <b-form-group :label="$t('portal.workers_type')" label-for="workers_type">
                                            <p class="input-field">{{ getWorkerTypeName(appDetail.detail.workers_type) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.packet_uses_facilities">
                                          <b-form-group :label="$t('portal.packet_uses_facilities')" label-for="packet_uses_facilities">
                                            <p class="input-field">{{ appDetail.detail.packet_uses_facilities }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail?.detail?.ingredients_use_in_packet">
                                          <b-form-group :label="$t('portal.ingredients_use_in_packet')" label-for="ingredients_use_in_packet">
                                            <p class="input-field">{{ appDetail.detail.ingredients_use_in_packet }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                  </div>
                                  <div class="group-form-card" v-if="appDetail.final_mark">
                                    <b-form-group label-cols-lg="3" :label="$t('national_award.result')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('national_award.final_result')" label-for="final_result">
                                            <p class="input-field">{{ $n(appDetail.final_mark) }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                  </div>
                                </div>
                              </div>
                              <!-- documents -->
                              <b-row>
                                 <b-col md="6">
                                  <div>
                                    <h6 class="mb-3 tag">{{ $t('dealer.important_document') }}</h6>
                                  </div>
                                  <div>
                                    <ul>
                                      <li v-if="appDetail?.detail?.packet_picture_attachment">
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(1) }}. {{ $t('portal.packet_picture_attachment') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.detail.packet_picture_attachment">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(appDetail.detail.packet_picture_attachment) ? 'storage/' : '') + appDetail.detail.packet_picture_attachment"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                      </li>
                                      <li v-if="appDetail?.detail?.packet_details_design_attachment">
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(2) }}. {{ $t('portal.packet_details_design_attachment') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.detail.packet_details_design_attachment">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(appDetail.detail.packet_details_design_attachment) ? 'storage/' : '') + appDetail.detail.packet_details_design_attachment"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                          <b-col md="12" class="text-right">
                            <b-button variant="primary " class="btn-sm" @click="back()">{{ $t('globalTrans.back') }}</b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </b-overlay>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </template>
      </body-card>
    </card>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import Loading from './loading/Details.vue'
export default {
  name: 'Details',
  components: {
    Loading
  },
  data () {
    return {
      loading: false,
      forwarLoad: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      appDetail: {},
      forwardsShow: false,
      recommendationShow: false,
      users: []
    }
  },
  created () {
    this.appDetail = this.getAppDetail()
  },
  computed: {
    isExternalUser () {
      let isExternal = false
      if (this.$store.state.Auth.authUser.user_type === 2) {
        isExternal = true
      }
      return isExternal
    },
    currentLocale () {
      return this.$i18n.locale
    },
    appId () {
      return this.$route.params.id
    },
    stepList () {
      const setp = [
        { value: 1, text: this.$t('national_award.submitted') },
        { value: 2, text: this.$t('national_award.application_received') }
      ]
      return setp
    },
    orgType () {
      return [
        {
            value: 1,
            text: this.$i18n.locale === 'en' ? 'Tea Garden' : 'চা বাগান'
        },
        {
            value: 2,
            text: this.$i18n.locale === 'en' ? 'Tea Factory' : 'চা কারখানা'
        },
        {
            value: 3,
            text: this.$i18n.locale === 'en' ? 'Export Company' : 'রপ্তানি কোম্পানি'
        },
        {
            value: 4,
            text: this.$i18n.locale === 'en' ? 'Person' : 'ব্যক্তি'
        },
        {
            value: 5,
            text: this.$i18n.locale === 'en' ? 'Blender' : 'ব্লেন্ডার'
        },
        {
            value: 6,
            text: this.$i18n.locale === 'en' ? 'Small Farmer' : 'ক্ষুদ্র চা চাষি'
        }
      ]
    },
    yesNoList () {
      return [
          {
              value: 1,
              text: this.$i18n.locale === 'en' ? 'Yes' : 'হ্যাঁ'
          },
          {
              value: 0,
              text: this.$i18n.locale === 'en' ? 'No' : 'না'
          }
      ]
    },
    workerType () {
        return [
            {
                value: 1,
                text: this.$i18n.locale === 'en' ? 'Registered' : 'নিবন্ধিত'
            },
            {
                value: 2,
                text: this.$i18n.locale === 'en' ? 'Unregistered' : 'অনিবন্ধিত'
            }
        ]
    }
  },
  methods: {
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    back () {
      this.$router.go(-1)
    },
    async getAppDetail () {
        this.loading = true
        const result = await RestApi.getData(teaGardenServiceBaseUrl, '/btb/national-tea-award/application/show' + '/' + this.appId)
        if (result.success) {
          this.appDetail = result.data.app
        }
        this.loading = false
    },
    getOrgTypeName (id) {
      const data = this.orgType.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return data.text
      } else {
        return ''
      }
    },
    getYesNoName (id) {
      const data = this.yesNoList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return data.text
      } else {
        return ''
      }
    },
    getWorkerTypeName (id) {
      const data = this.workerType.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return data.text
      } else {
        return ''
      }
    },
    async pdfExport () {
      this.loading = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 36)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, '/btb/license-application/bidder-view-pdf', params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      this.loading = false
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getCircularName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.teaAwardCircularList.find(item => item.value === id)
      if (obj) {
        return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
      }
      return ''
    },
    getCategoryName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.teaAwardCategoryList.find(item => item.value === id)
      if (obj) {
        return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
      }
      return ''
    }
  }
}
</script>
<style scoped>
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
    }

    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }

    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
.tagTwo {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
  background: hsl(250deg, 100%, 40%);
  padding: 5px 25px;
  font-weight: 600;
  font-size: 12px;
  color: #FFF;
  transition: clip-path 500ms;
}
.tagTwo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: hsl(252deg, 100%, 60%);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 0;
  transition: transform 500ms;
}
</style>
